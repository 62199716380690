import React from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {useIntl} from "react-intl";

const MessageSent = () => {
  const intl = useIntl();

    return (
      <Layout>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.contact.message-sent" })} />

        <div className="white-container mb-5">


          <div className="text-center mb-4">

            <span className="icon-check fs-40 green"></span>

          </div>

          <h1 className="text-center">{intl.formatMessage({ id: "contact.form_sent.title" })}</h1>

          <p className="text-center mb-4">{intl.formatMessage({ id: "contact.form_sent.description" })}
          </p>


          <div className="">
            <Link to="/maintenance/timeline" className="button red mx-auto">{intl.formatMessage({ id: "contact.form_sent.button" })}</Link>
          </div>

        </div>

      </Layout>
    )
}

export default MessageSent
